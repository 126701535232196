import React from 'react';
import { graphql,Link } from "gatsby";
import '../styles/global.css';
import { useIntl } from "gatsby-plugin-intl";
import Layout from '../components/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Seo from '../components/seo';

const ServicePage = ({data}) => {
    library.add(fas)  

    const stylingName = (name) =>{  
      let finalClass = name;
      const rtl = intl.locale !== "fa" ? false : true ;
      if (rtl) {
        finalClass = `${name}RTL` ; 
      } 
      return finalClass; 
    }
  
    const intl = useIntl()
    const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
    
    return (
      <Layout 
        isHome={false}>
          <div className={stylingName("container")}>
            <section className="post-feed">
              {
                data.allContentfulService.edges.map((edge) => (
                  <Link  
                  key={edge.node.id} 
                  className="post-card"
                  to={`${locale}/services/${edge.node.slug}`}>
                    <header className="post-card-header">
                      <FontAwesomeIcon 
                        icon={edge.node.icon}
                        className="service-icon"/>
                      <h2 className="post-card-title">{intl.formatMessage({id: `${edge.node.title}`})}</h2>
                    </header>
                    <h3 className="post-card-excerpt">{intl.formatMessage({id: `${edge.node.subtitle}`})}</h3>
                    <h4 className="post-card-footer">{intl.formatMessage({id: `${edge.node.targetAudience}`})}</h4>
                </Link>
                ))
              }
            </section>
          </div>
      </Layout>
    )
}

export const Head = ({ pageContext }) => (
  <Seo
  title={`${pageContext.intl.messages["Services"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
  article={false}>
  </Seo>
)

export default ServicePage

export const pageQuery = graphql`
query ServiceQuery {
  allContentfulService(sort: {fields: priority, order: ASC}) {
    edges {
      node {
        title
        subtitle
        targetAudience
        icon
        id
        slug
      }
    }
  }
}

`; 
